import { MEDIAREF } from './AssetLibrary';
import gql from 'graphql-tag';

export const GDPR_SETTINGS_FRAGMENT = gql`
  fragment GdprSettings on GdprSettings {
    bannerMode
    bannerText
    bannerCode
  }
`;

export const TEAM_SETTINGS_FRAGMENT = gql`
  fragment TeamSettings on TeamSettings {
    hideBranding
    gdpr {
      ...GdprSettings
    }
  }
  ${GDPR_SETTINGS_FRAGMENT}
`;

export const PUBLIC_TEAM_FRAGMENT = gql`
  fragment PublicTeam on Team {
    id
    name
    logoMediaRef {
      ...MediaRef
    }
    primaryColor
    actionColor
    subdomain
    domain
    isDomainVerified
    shareUrl
    settings {
      ...TeamSettings
    }
  }
  ${MEDIAREF}
  ${TEAM_SETTINGS_FRAGMENT}
`;

export const TEAM_FRAGMENT = gql`
  fragment Team on Team {
    ...PublicTeam
    subscriptionStatus
    subscriptionSubstatus
    trialEndingDate
    timezone
    createdAt
    registrantLimit {
      limit
      registrants
      isFull
      registrantsLeftPercent
      includedLimit
      additionalLimit
      purchasedAdditionalLimit
      resetAt
      isTrialing
    }
  }
  ${PUBLIC_TEAM_FRAGMENT}
`;

export const TEAM_DOMAIN_SETTINGS_FRAGMENT = gql`
  fragment TeamDomainSettings on Team {
    id
    subdomain
    domain
    canonicalDomain
    isDomainVerified
  }
`;
