import gql from 'graphql-tag';

import { MEDIAREF } from './AssetLibrary';
import { TEAM_FRAGMENT, TEAM_SETTINGS_FRAGMENT } from './TeamFragment';

export const PRESENTER_FRAGMENT = gql`
  fragment Presenter on User {
    id
    profileMediaRef {
      ...MediaRef
    }
    name
    firstName
    lastName
    email
    phone
    company
    title
    bio
    displayEmail
    timezone
    socialLinks {
      facebook
      twitter
      linkedin
      instagram
    }
  }
  ${MEDIAREF}
`;

export const TEAM_MEMBER_FRAGMENT = gql`
  fragment Member on UserInTeam {
    id
    profileMediaRef {
      ...MediaRef
    }
    name
    firstName
    lastName
    email
    phone
    company
    title
    bio
    displayEmail
    socialLinks {
      facebook
      twitter
      linkedin
      instagram
    }
    timezone
    state
    version
    isVerified
    team {
      id
      name
      subdomain
      logoMediaRef {
        ...MediaRef
      }
      primaryColor
      actionColor
    }
    assignedSets {
      id
    }
    integrations {
      firstPromoterToken
    }
    role
    invitationStatus
    companyRole
    companyRoleOther
  }
  ${MEDIAREF}
`;

export const USER_SET_FRAGMENT = gql`
  fragment UserInSet on UserInSet {
    id
    firstName
    lastName
    name
    email
    profileMediaRef {
      ...MediaRef
    }
    role
    invitationStatus
    setId
    order
  }
  ${MEDIAREF}
`;

export const USER_FRAGMENT = gql`
  fragment User on User {
    ...Presenter
    state
    version
    isVerified
    role
    invitationStatus
    lastCountry
    team {
      id
      name
      subdomain
      logoMediaRef {
        ...MediaRef
      }
      primaryColor
      actionColor
      address {
        country
      }
      settings {
        ...TeamSettings
      }
    }
  }
  ${MEDIAREF}
  ${PRESENTER_FRAGMENT}
  ${TEAM_SETTINGS_FRAGMENT}
`;

export const ME_FRAGMENT = gql`
  fragment Me on Me {
    id
    profileMediaRef {
      ...MediaRef
    }
    lastCountry
    name
    firstName
    lastName
    email
    hasPassword
    phone
    company
    title
    bio
    displayEmail
    integrations {
      firstPromoterToken
    }
    socialLinks {
      facebook
      twitter
      linkedin
      instagram
    }
    timezone
    state
    version
    isVerified
    verifiedAt
    team {
      ...Team
      favIconMediaRef {
        ...MediaRef
      }
      extraScopes
      mailAddress
      promoCode
      billingCycle
      settings {
        ...TeamSettings
      }
      timeSavedSummary
      isEnabledTest
      pendingSetupIntent
    }
    isInvitedUser
    inTeam {
      role
      invitationStatus
      invitedByUser {
        id
        firstName
        lastName
      }
    }
    impersonatingUser {
      userId
      teamId
    }
    chatTokens {
      accessToken
      refreshToken
    }
    companyRole
    companyRoleOther
    lastLoginAt
  }
  ${MEDIAREF}
  ${TEAM_FRAGMENT}
  ${TEAM_SETTINGS_FRAGMENT}
`;

export const SKIP_VERIFY_EMAIL = gql`
  mutation skipVerifyEmail {
    skipVerifyEmail {
      ...User
    }
  }
  ${USER_FRAGMENT}
`;

export const ME = gql`
  query me {
    me {
      ...Me
      now
    }
  }
  ${ME_FRAGMENT}
`;

export const HUBSPOT_TOKEN = gql`
  query hubspotToken {
    hubspotToken
  }
`;

export const FP_REWARDS = gql`
  mutation registerForRewards {
    registerForRewards {
      ...Me
    }
  }
  ${ME_FRAGMENT}
`;

export const UPDATE_ME = gql`
  mutation updateMe($data: UpdateMeInput!) {
    updateMe(data: $data) {
      ...Me
    }
  }
  ${ME_FRAGMENT}
`;

export const REGISTER = gql`
  mutation register($data: NewUserAndTeamInput!) {
    registerUserAndTeam(data: $data) {
      ...Me
    }
  }
  ${ME_FRAGMENT}
`;

export const LOGIN = gql`
  mutation login(
    $email: String!
    $password: String!
    $locale: String!
    $identities: UserIdentitiesInput
  ) {
    login(email: $email, password: $password, locale: $locale, identities: $identities) {
      ...Me
      now
    }
  }

  mutation loginSSO(
    $provider: String!
    $token: String!
    $locale: String!
    $identities: UserIdentitiesInput
  ) {
    loginSSO(provider: $provider, token: $token, locale: $locale, identities: $identities) {
      ...Me
      now
    }
  }

  ${ME_FRAGMENT}
`;

export const LOGOUT = gql`
  mutation logout {
    logout
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation changePassword($data: ChangePasswordInput!) {
    changePassword(data: $data) {
      ...Me
    }
  }
  ${ME_FRAGMENT}
`;

export const RESET_PASSWORD = gql`
  mutation resetPassword($email: String!) {
    resetPassword(email: $email)
  }
`;

export const RESEND_CONFIRMATION_EMAIL = gql`
  mutation resendConfirmationEmail {
    resendConfirmationEmail
  }
`;

export const VERIFY_TOKEN = gql`
  mutation verifyToken($token: String!, $locale: String, $identities: UserIdentitiesInput) {
    verifyToken(token: $token, locale: $locale, identities: $identities) {
      ...Me
    }
  }
  ${ME_FRAGMENT}
`;

export const ACCEPT_INVITATION = gql`
  mutation acceptInvitation($token: String!) {
    acceptInvitation(token: $token) {
      ...Me
    }
  }
  ${ME_FRAGMENT}
`;

export const REJECT_INVITATION = gql`
  mutation rejectInvitation($token: String!) {
    rejectInvitation(token: $token)
  }
`;

export const REFRESH_TOKEN = gql`
  mutation refreshToken {
    refreshToken
  }
`;
